@keyframes slide-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-in-from-bottom{
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.App {
  position: relative;
  max-width: 1440px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0 auto;
  padding: 0;
}

.header {
  width: 80%;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgb(41, 159, 255);
  border-bottom: 1px solid rgba(210, 210, 210, 0.582);
  margin: 0;
  padding: 20px 0;
}

.header__logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.header__logo-text {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0;
}

.header__logo {
  width: 80px;
  margin: 0;
  padding: 0;
}

.header__contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.header__contact-icon {
  width: 40px;
  margin: 0;
  padding: 0;
}

.header__contact-text {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 500;
  text-align: right;
  margin: 0;
  padding: 0;
}

.header__contact-phone {
  font-size: 1.7rem;
  line-height: 2.5rem;
  font-weight: 500;
  border-right: 1px solid;
  margin: 0 10px 0 0;
  padding: 0 20px;
  user-select: text;
}

.header__contact-phone a {
  text-decoration: none;
  color: rgb(41, 159, 255);
}

.sticky {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.nav__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav__item {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  margin: 30px;
  padding: 0;
}

.nav__item:hover {
  cursor: pointer;
  color: rgb(95, 182, 252);
}

.cover {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background-image: url("./images/home-slider.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.cover__container {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 80px 0 0 20px;
  padding: 0;
}

.cover__text-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: right;
  margin: 0;
  padding: 0;
  opacity: 0;
  transform: translateX(-100px);
  animation: slide-in-from-left 1s ease-out forwards;
}

.cover__text {
  font-size: 2rem;
  line-height: 3rem;
  margin: 0;
  padding: 0;
}

.cover__text:nth-child(2) {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
}

.cover__tags-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: right;
  margin: 0;
  padding: 0;
}

.cover__tag {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  opacity: 0;
  margin: 0;
  padding: 0;
}

.cover__tag_active {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  opacity: 0;
  transform: translateX(-50px);
  animation: slide-in-from-left 0.5s ease-out forwards;
  margin: 0;
  padding: 0;
}

.cover__img {
  width: 60px;
}

.cover__tag-text {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.cover__button {
  align-self: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  outline: none;
  border: white solid 1px;
  border-radius: 20px;
  background: linear-gradient(140deg, rgba(0,212,255,1) 0%, rgba(41,159,255,1) 30%, rgba(41,159,255,1) 70%, rgba(0,212,255,1) 100%); 
  color: rgb(255, 255, 255);
  box-shadow: 10px 10px 20px -8px rgba(106, 103, 103, 0.45);
  opacity: 0;
  margin: 80px 0 60px -600px;
  padding: 15px 40px;
  cursor: pointer;
}

.cover__button_active {
  align-self: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  outline: none;
  border: white solid 1px;
  border-radius: 20px;
  background: linear-gradient(140deg, rgba(0,212,255,1) 0%, rgba(41,159,255,1) 30%, rgba(41,159,255,1) 70%, rgba(0,212,255,1) 100%); 
  color: rgb(255, 255, 255);
  box-shadow: 10px 10px 20px -8px rgba(106, 103, 103, 0.45);
  margin: 80px 0 60px -600px;
  padding: 15px 40px;
  cursor: pointer;
  transform: translateY(-100px);
  animation: slide-in-from-bottom 0.5s ease-out forwards;
}

.laundry {
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 75px 0 0;
}

.laundry__title {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 500;
  text-align: center;
}

.laundry__subtitle {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: right;
  margin: 10px 0;
  padding: 0;
}

.laundry__section-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 80px 0 0;
  padding: 0;
}

.laundry__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  margin: 0;
  padding: 0;
}

.laundry__section-img {
  width: 30%;
}

.laundry__section-text {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
}

.work {
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  padding: 100px 0 0;
}

.work__title {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 500;
  align-self: center;
  margin: 0;
  padding: 0;
}

.work__container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.work__text-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 50px 0 0 50px;
}

.work__subtitle {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: right;
  margin: 10px 0 0;
  padding: 0;
}

.work__img {
  width: 50%;
  margin: 0;
  padding: 0;
}

.about {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 100px 0;
}

.about__title {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.about__subtitle {
  width: 80%;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin: 50px 0 0;
  padding: 0;
}

.about__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px 0 0;
  padding: 0;
}

.about__text-title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  text-align: right;
  margin: 0 0 10px;
  padding: 0;
}

.about__text {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: right;
  margin: 0;
  padding: 0;
}

.about__text-sub {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: right;
  margin: 10px 0 0;
  padding: 0;
  user-select: text;
}

.about__text-sub a {
  color: black;
}

.about__map {
  width: 50%;
  border: none;
  margin: 0;
  padding: 0;
}

.footer {
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 0;
  padding: 20px;
}

.footer__text {
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-decoration: none;
  color: rgb(109, 109, 109);
  margin: 20px 0 10px;
  padding: 0;
}

.footer__text:hover {
  color: black;
}

@media screen and (max-width:1280px) {
  .cover__container {
    gap: 40px;
    margin: 60px 0 0 20px;
    padding: 0;
  }
  .cover__button {
    margin: 60px 0 20px -600px;
  }
  
  .cover__button_active {
    margin: 60px 0 20px -600px;
  }
  .laundry__title {
    font-size: 2rem;
  }
  .laundry__subtitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .laundry__section-text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .work {
    margin: 70px auto;
  }
  .work__title {
    font-size: 2rem;
  }
  .work__text-container {
    padding: 70px 0 0 50px;
  }
  .work__subtitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .about__title {
    font-size: 2rem;
  }
  .about__subtitle {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 70px 0 0;
  }
  .about__container {
    margin: 70px 0 50px;
  }
  .about__text-title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 10px 0 0;
  }
  .about__text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .footer__text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media screen and (max-width:1024px) {
  .header {
    width: 85%;
    padding: 15px 0;
  }
  .header__contact-text {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .header__contact-phone {
    font-size: 1.3rem;
    line-height: 1.2rem;
    padding: 0 10px 0 0;
  }
  .header__contact-icon {
    width: 33px;
    margin-left: 0;
  }
  .header__logo {
    width: 60px;
  }
  .nav__item {
    font-size: 1.1rem;
    margin: 20px;
  }
  .cover__container {
    width: 45%;
    gap: 20px;
    margin: 40px 0 0 10px;
  }
  .cover__text {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .cover__text:nth-child(2) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .cover__img {
    width: 50px;
  }
  .cover__tags-container {
    gap: 10px;
  }
  .cover__tag-text {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .cover__button {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 40px 0 30px -400px;
  }
  .cover__button_active {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 40px 0 30px -400px;
  }
  .laundry {
    margin: 0 auto;
    padding: 100px 0 0;
  }
  .laundry__title {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .laundry__subtitle {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .laundry__section-text {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .laundry__section-container {
    gap: 40px;
    margin: 50px 0 0;
  }
  .work {
    margin: 0 auto;
  }
  .work__title {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
  .work__text-container {
    padding: 40px 0 0 50px;
  }
  .work__subtitle {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .about {
    padding: 100px 0 20px;
  }
  .about__title {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .about__subtitle {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 50px 0 0;
  }
  .about__container {
    margin: 70px 0 50px;
  }
  .about__text-title {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 10px 0 0;
  }
  .about__text {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .about__text-sub {
    font-size: 1.2rem;
    line-height: 1.6rem;
    }
  .footer__text {
    font-size: 0.9rem;
    line-height: 1rem;
  }
}

@media screen and (max-width:780px) {
  .header {
    width: 90%;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 10px 0;
    gap: 10px;
  }
  .cover {
    background-size: 110%;
  }
  .cover__container {
    width: 50%;
    gap: 10px;
    margin: 40px 0 0 10px;
  }
  .cover__tags-container {
    align-items: right;
  }
  .cover__button {
    margin: 50px 0 20px;
  }
  .cover__button_active {
    margin: 50px 0 20px;
  }
  .laundry {
    margin: 0 auto;
    padding: 80px 0 0;
  }
  .about {
    width: 90%;
    padding: 80px 0 0;
  }
  .about__container {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
  }
  .about__text-title {
    text-align: center;
  }
  .about__text {
    text-align: center;
  }
  .about__text-sub {
    text-align: center;
    user-select: text;
  }
  .about__map {
    width: 90%;
    height: 250px;
    border: none;
    margin: 50px 0 10px;
    padding: 0;
  }
  .footer {
    justify-content: center;
    margin: 10px;
    padding: 0;
  }
}

@media screen and (max-width:668px) {
  .cover {
    background-size: 115%;
  }
  .cover__container {
    width: 52%;
  }
}

@media screen and (max-width:642px) {
  .cover {
    background-size: 120%;
  }
  .cover__container {
    width: 55%;
    margin: 40px 0 0 5px;
  }
}

@media screen and (max-width:560px) {
  .header {
    width: 100%;
  }
  .header__contact {
    gap: 0;
    margin: 0;
    padding: 10px 0 0;
  }
  .header__contact-text {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .header__contact-phone {
    font-size: 1rem;
    padding: 0 5px 0 0;
    margin: 5px;
  }
  .header__contact-icon {
    width: 25px;
    margin-left: 5px;
  }
  .header__logo {
    width: 40px;
  }
  .nav__item {
    font-size: 0.9rem;
    margin: 15px 12px;
  }
  .cover {
    background-size: 120%;
  }
  .cover__container {
    width: 53%;
    gap: 0;
    margin: 30px 0 0 10px;
  }
  .cover__text {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .cover__text:nth-child(2) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .cover__tags-container {
    margin: 15px 0 0;
  }
  .cover__tag_active {
    gap: 10px;
  }
  .cover__img {
    width: 30px;
  }
  .cover__tag-text {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 900;
  }
  .cover__button {
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin: 30px 0 20px;
    padding: 10px 30px;
  }
  .cover__button_active {
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin: 30px 0 20px;
    padding: 10px 30px;
  }
  .laundry {
    margin: -20px auto 0;
    padding: 50px 0 0;
  }
  .laundry__title {
    font-size: 1.2rem;
    font-weight: 900;
  }
  .laundry__subtitle {
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
  }
  .laundry__section-container {
    gap: 40px;
    margin: 40px 0 0;
    padding: 0;
  }
  .laundry__section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
  .laundry__section-img {
    width: 90%;
  }
  .laundry__section-text {
    font-size: 1rem;
    text-align: center;
  }
  .work {
    width: 90%;
    padding: 80px 0 0;
  }
  .work__title {
    font-size: 1.2rem;
    font-weight: 900;
  }
  .work__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .work__text-container {
    margin: 20px 0 0;
    padding: 0;
  }
  .work__subtitle {
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    margin: 10px 0 0;
    padding: 0;
  }
  .work__img {
    width: 90%;
  }
  .about__title {
    font-size: 1.2rem;
    font-weight: 900;
  }
  .about__subtitle {
    width: 100%;
    font-size: 1rem;
    line-height: 1.2rem;
    margin: 30px 0 0;
  }
  .about__subtitle:last-of-type {
    margin: 20px 0 0;
    padding: 0;
  }
  .about__text-title {
    font-size: 1.2rem;
    line-height: 1rem;
    font-weight: 900;
    text-align: center;
    margin: 20px 0 0;
  }
  .about__text {
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
  }
  .about__text-sub {
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  }
  .about__map {
    width: 100%;
    height: 250px;
    border: none;
    margin: 20px 0;
    padding: 0;
  }
  .footer {
    justify-content: center;
    margin: 0 10px 10px;
  }
  .footer__text {
    font-size: 0.8rem;
  }
}

@media screen and (max-width:440px) {
  .cover {
    background-size: 150%;
  }
  .cover__container {
    width: 70%;
    gap: 0;
    margin: 30px 0 0;
  }
}
